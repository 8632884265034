import React, { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import { COMPARISON_SIGNS } from '../../consts/CommonConstants';
import failureIcon from '../../images/important_note.svg';
import successTick from '../../images/success_tick_external.svg';
import parseHTML from '../../services/HTMLParser';
import httpMessenger from '../../services/HTTPMessenger';
import validationService from '../../Shared/ValidationService';
import DomRender from '../DomRender';
import Banner from '../UI/Banner';
import Modal from '../UI/Modal';
import styles from './BookDCSessionDialog.module.css';

export default function BookDCSessionDialog({ availableUnits, closeDialog }) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageParams, setErrorMessageParams] = useState({});
  const [DCSessions, setDCSessions] = useState(null);
  const bannerMessage = 'book-debriefing-only-for-select-grow-reports-warning';
  const { t } = useTranslation();
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    /* This does not change the value of modalInfo, but updates the values inside
        the function calls handleSubmit and closeDialog to hold the latest
        values of the useState variable (payload) */
    setModalInfo((modalInfo) => ({
      ...modalInfo,
      ...{ formActions: getDebriefingCoachingModalFormActions() },
    }));
  }, [DCSessions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModalInfo({
      headerText: t('product-group.name.debriefing-and-coaching'),
      headerHint: getDCHeaderHint(),
      showDCForm: true,
      formActions: { ...getDebriefingCoachingModalFormActions() },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (value) => {
    setDCSessions(value);
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    if (debriefingCoachingForm.instantValidations) {
      handleValidations(debriefingCoachingForm.instantValidations, value);
    } else {
      setError(false);
      setErrorMessage('');
      setErrorMessageParams({});
    }
  };

  const showDebriefingRequestModal = () => {
    setModalInfo({
      headerText: t('product-group.name.debriefing-and-coaching'),
      headerHint: getDCHeaderHint(),
      showDCForm: true,
      formActions: { ...getDebriefingCoachingModalFormActions() },
    });
  };

  const getDCHeaderHint = () => {
    let finalDescription =
      '<span style ="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/><span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span><br/><br/><span style="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/> <span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span>';
    let descriptionKeys = [
      'general.debriefing',
      'product-details.debriefing-description',
      'general.coaching',
      'product-details.coaching-description',
    ];
    descriptionKeys.forEach((key) => {
      finalDescription = finalDescription.replace('PLACE_HOLDER', t(key));
    });
    return parseHTML(finalDescription);
  };

  const getDebriefingCoachingModalFormActions = () => {
    return {
      rightButton: {
        text: t('general.send-request'),
        action: () => sendDCRequest(),
        style: styles.modal_submit_button_style,
      },
      leftButton: {
        text: t('general.cancel'),
        action: modalClose,
        style: styles.modal_cancel_button_style,
      },
    };
  };

  const sendDCRequest = () => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let isValid;
    isValid = handleValidations(debriefingCoachingForm.instantValidations, DCSessions);
    if (isValid) {
      isValid = handleValidations(debriefingCoachingForm.submitValidations, DCSessions);
    }
    if (isValid) {
      setModalInfo({
        text: parseHTML(
          t('product-details.total-price-of-sessions', {
            total: DCSessions * 1, //TODO: Replace 1 by actual price per DC session
            amountOfSessions: DCSessions,
          }) +
            '<br/><br/>' +
            t('total-units-create-invitations', {
              total: DCSessions * 1, //TODO: Replace 1 by actual price per DC session
            }),
        ),
        formActions: {
          rightButton: {
            text: t('general.confirm'),
            action: () => confirmDCRequest(),
            style: styles.modal_submit_button_style,
          },
          leftButton: {
            text: t('general.cancel'),
            action: (e) => cancelDCSessionsRequest(),
            style: styles.modal_cancel_button_style,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const confirmDCRequest = async () => {
    const body = {
      amount: DCSessions,
    };
    let httpResult = await httpMessenger.apiCall('POST', 'sendUnlinkedDCRequest', body);
    if (httpResult.success) {
      setModalInfo({
        text: parseHTML(
          t('thanks-for-booking-debriefing-and-coaching-text1') +
            '<br/>' +
            t('thanks-for-booking-debriefing-and-coaching-text2'),
        ),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: () => modalClose(Number(DCSessions)),
          },
        },
        icon: successTick,
      });
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const cancelDCSessionsRequest = () => {
    showDebriefingRequestModal();
  };

  const handleValidations = (validations, validateValue) => {
    let isValid,
      message,
      messageParams = {};
    if (validations) {
      for (let validation of validations) {
        [isValid, message, messageParams] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError(true);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
          break;
        } else {
          setError(false);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  const getDebriefingCoachingProps = () => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let updatedProps = {
      required:
        (debriefingCoachingForm.submitValidations &&
          debriefingCoachingForm.submitValidations.some(
            (validation) => validation.type === 'required',
          )) ||
        (debriefingCoachingForm.instantValidations &&
          debriefingCoachingForm.instantValidations.some(
            (validation) => validation.type === 'required',
          )),
      additionalStyles: debriefingCoachingForm.additionalStyles,
      inputType: debriefingCoachingForm.inputType,
      placeholder: debriefingCoachingForm.placeholder,
      defaultValue: DCSessions,
      error: error,
      errorMessage: errorMessage,
      errorMessageParams: errorMessageParams,
    };
    return updatedProps;
  };

  const getDebriefingAndCoachingForm = () => {
    return {
      label: '',
      type: 'text',
      inputType: 'number',
      additionalStyles: {
        container: styles.sesions_input_container,
      },
      placeholder: '0',
      instantValidations: [
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.GT,
            expectation: 0,
            errorMessage: 'error.value.invalid-sessions',
            errorMessageParams: {},
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.LTEQ,
            expectation: Math.floor(availableUnits / 1), //TODO: Replace 1 by actual price per DC session
            errorMessage:
              availableUnits === 0
                ? 'user-dashboard.no-units-and-contact-sales'
                : 'error.value.sessions-gt-x',
            errorMessageParams: {
              max: Math.floor(availableUnits / 1), //TODO: Replace 1 by actual price per DC session
            },
          },
        },
      ],
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    };
  };

  const modalClose = (usedUnits) => {
    setModalInfo({
      formActions: null,
      headerHint: null,
      headerText: null,
    });
    setError(false);
    setErrorMessage('');
    setDCSessions(null);
    closeDialog(usedUnits);
  };

  const bannerMetadata = {
    style: {
      message_span: styles.banner_message_span,
      banner_container: styles.banner_container,
    },
  };

  return (
    <Modal
      formActions={modalInfo.formActions}
      headerText={modalInfo.headerText}
      headerHint={modalInfo.headerHint}
      onClose={modalClose}
    >
      <FlexView column className={styles.modal_content_container}>
        {modalInfo.icon && (
          <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
        )}
        <span className={styles.modal_content_text}>{modalInfo.text}</span>
        {modalInfo.showDCForm && (
          <>
            <Banner message={bannerMessage} metadata={bannerMetadata} />
            <span className={styles.modal_debriefing_coaching_content}>
              {t('book-debriefing-and-coaching-text1') +
                ' ' +
                t('book-debriefing-and-coaching-text2')}
            </span>
            <span className={styles.modal_debriefing_coaching_content}>
              {t('book-debriefing-and-coaching.select-amount-of-sessions')}
            </span>
            <DomRender
              elemType={'text'}
              {...getDebriefingCoachingProps()}
              callback={(value) => handleChange(value)}
            />
          </>
        )}
      </FlexView>
    </Modal>
  );
}
