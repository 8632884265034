import { COMPARISON_SIGNS, NULL, UNDEFINED } from '../consts/CommonConstants';
import i18n from '../i18n/config';

class ValidationService {
  validations = {
    email: {
      regExp: new RegExp(/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
      errorMessage: 'error.email.invalid',
    },
    shortLength: {
      errorMessage: 'error.message.too-short',
    },
    valueCompare: {
      errorMessage: 'error.invalid-value',
    },
    required: {
      errorMessage: 'error.required',
    },
    emailList: {
      errorMessage: 'error.email.one-or-more-invalid',
    },
    validateType: {
      errorMessage: 'error.invalid-value',
    },
  };

  //returns true if validation is successful.
  validate = (code, value, attributes) => {
    if (code === 'email') {
      return this.validateEmail(value);
    } else if (code === 'shortLength') {
      return this.validateShortLength(value, attributes);
    } else if (code === 'valueCompare') {
      return this.valueCompare(value, attributes);
    } else if (code === 'required') {
      return this.validateRequired(value, attributes);
    } else if (code === 'email_list') {
      return this.validateEmailList(value);
    } else if (code === 'validateType') {
      return this.validateType(value, attributes);
    }
    return [true, ''];
  };

  validateEmail = (value) => {
    let validEmail = this.validations.email.regExp;
    let isValidEmail = true;
    let errorMessage = '';
    if (!validEmail.test(value)) {
      isValidEmail = false;
      errorMessage = this.validations.email.errorMessage;
    }
    return [isValidEmail, errorMessage];
  };

  validateEmailList = (emailList) => {
    let validEmail = this.validations.email.regExp;
    let isValidEmail = true;
    let errorMessage = '';

    // if (emailList.length === 0) {
    // isValidEmail = false;
    // errorMessage = this.validations.email.errorMessage;
    // } else {
    let emails = emailList.split(';').map((email) => {
      return email.trim();
    });
    emails.every((email) => {
      if (email !== '' && !validEmail.test(email)) {
        isValidEmail = false;
        errorMessage = this.validations.emailList.errorMessage;
        return false;
      }
      return true;
    });
    // }
    return [isValidEmail, errorMessage];
  };

  validateShortLength = (value, attributes) => {
    let isValidLength = true;
    let errorMessage = '';
    if (value !== null && value !== UNDEFINED && value.length < attributes.minLength) {
      isValidLength = false;
      errorMessage = attributes.errorMessage || this.validations.shortLength.errorMessage;
    }
    return [isValidLength, errorMessage];
  };

  valueCompare = (value, attributes) => {
    let isValidValue = true;
    let errorMessage = '';
    let errorMessageParams = {};
    if (value !== NULL && value !== UNDEFINED) {
      if (attributes.compareSign === COMPARISON_SIGNS.GT) {
        if (value <= attributes.expectation) {
          isValidValue = false;
        }
      } else if (attributes.compareSign === COMPARISON_SIGNS.LTEQ) {
        if (value > attributes.expectation) {
          isValidValue = false;
        }
      } else if (attributes.compareSign === COMPARISON_SIGNS.GTEQ) {
        if (value < attributes.expectation) {
          isValidValue = false;
        }
      }
      if (!isValidValue) {
        errorMessage = attributes.errorMessage || this.validations.valueCompare.errorMessage;
        errorMessageParams = attributes.errorMessageParams;
      }
    }
    return [isValidValue, errorMessage, errorMessageParams];
  };

  validateRequired = (value, attributes) => {
    let isValidField = true;
    let errorMessage = '';
    if (value === NULL || value === UNDEFINED || value === '') {
      isValidField = false;
      errorMessage = attributes.errorMessage || this.validations.required.errorMessage;
    }
    return [isValidField, errorMessage];
  };

  validateType = (value, attributes) => {
    let isValidField = true;
    let errorMessage = '';
    if (attributes.type === 'number' && isNaN(value)) {
      isValidField = false;
      errorMessage = attributes.errorMessage || this.validations.validateType.errorMessage;
    }
    return [isValidField, errorMessage];
  };
}

const validationService = new ValidationService();
export default validationService;
