import { AD_COACH, AD_SALES, AD_USER } from '../consts/Roles';

class UserProfileService {
  userProfile = null;
  currentRole = null;
  getUserProfile = () => {
    return this.userProfile;
  };

  setUserProfile = (user) => {
    this.userProfile = user;
  };

  getEmail = () => {
    return this.userProfile ? this.userProfile.email : null;
  };

  getFirstName = () => {
    return this.userProfile ? this.userProfile.given_name : null;
  };

  getZCompanyId = () => {
    try {
      return this.userProfile ? this.userProfile['company-id'][0] : null;
    } catch (err) {
      return null;
    }
  };

  getLastName = () => {
    return this.userProfile ? this.userProfile.family_name : null;
  };

  getCompany = () => {
    return this.userProfile
      ? this.userProfile.company
        ? this.userProfile.company[0]
        : null
      : null;
  };

  getUserPreferredLocale = () => {
    return this.userProfile ? (this.userProfile.locale ? this.userProfile.locale : null) : null;
  };

  hasRoles = (rolesToCheck) => {
    return rolesToCheck.every((role) => this.userProfile.resource_access.AD.roles.includes(role));
  };

  hasSomeRole = (rolesToCheck) => {
    return rolesToCheck.some((role) => this.userProfile.resource_access.AD.roles.includes(role));
  };

  getNumberOfRoles = () => {
    return this.userProfile?.resource_access?.AD?.roles?.length;
  };

  setCurrentRole = (role) => {
    this.currentRole = role;
  };

  getSub = () => {
    return this.userProfile ? this.userProfile.sub : null;
  };

  getCurrentRole = () => {
    if (this.currentRole !== null) return this.currentRole;
    if (
      this.userProfile &&
      this.userProfile.resource_access.AD &&
      this.userProfile.resource_access.AD.roles
    ) {
      if (this.userProfile.resource_access.AD.roles.includes(AD_SALES)) this.currentRole = AD_SALES;
      else if (this.userProfile.resource_access.AD.roles.includes(AD_COACH))
        this.currentRole = AD_COACH;
      else this.currentRole = AD_USER;
    }
    return this.currentRole;
  };
}

const userProfileService = new UserProfileService();
export default userProfileService;
