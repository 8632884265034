import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import DomRender from '../components/DomRender';
import Button from '../components/UI/Button';
import Chip from '../components/UI/Chip';
import parseHTML from '../services/HTMLParser';
import validationService from '../Shared/ValidationService';
import styles from './FormElement.module.css';

const FormElement = ({
  type,
  label,
  labelHint,
  labelHintParams,
  data,
  callback,
  trigger,
  submitValidation,
  attributeConfig,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageParams, setErrorMessageParams] = useState({});
  const [value, setValue] = useState(null);
  const [isFirst, setIsFirst] = useState(true);
  const [valueList, setValueList] = useState([]);
  const [resetkey, resetField] = useState(false);
  const isValueList =
    data &&
    ((data.instantValidations &&
      data.instantValidations.some(
        (instantValidation) => instantValidation.type === 'email_list',
      )) ||
      (data.submitValidations &&
        data.submitValidations.some((submitValidation) => submitValidation.type === 'email_list')));

  const getElementProps = () => {
    let updatedProps = {};
    try {
      switch (type) {
        case 'label':
          updatedProps.style = styles.label_additional_style;
          updatedProps.label = data.label;
          break;
        case 'dropdown':
          updatedProps.additionalStyles = {
            label: styles.dropdown_additional_label_style,
            container: styles.dropdown_additional_container_style,
          };
          updatedProps.required =
            (data.submitValidations &&
              data.submitValidations.some((validation) => validation.type === 'required')) ||
            (data.instantValidations &&
              data.instantValidations.some((validation) => validation.type === 'required'));
          updatedProps.options = data.options.map((option) => ({
            ...option,
            label: t(option.label),
          }));
          updatedProps.hint = t(data.hint, data.hint_params);
          updatedProps.placeholder = data.placeholder;
          updatedProps.defaultValue = data.defaultValue;
          updatedProps.description = data.description;
          updatedProps.descriptionKeys = data.descriptionKeys;
          updatedProps.error = error;
          updatedProps.errorMessage = errorMessage;
          updatedProps.errorMessageParams = errorMessageParams;
          break;
        case 'radiogroup':
          updatedProps.required =
            (data.submitValidations &&
              data.submitValidations.some((validation) => validation.type === 'required')) ||
            (data.instantValidations &&
              data.instantValidations.some((validation) => validation.type === 'required'));
          updatedProps.defaultValue = data.defaultValue;
          updatedProps.error = error;
          updatedProps.errorMessage = errorMessage;
          updatedProps.errorMessageParams = errorMessageParams;
          updatedProps.options = data.options.map((option) => ({
            ...option,
            label: t(option.label),
          }));
          updatedProps.code = data.code;
          break;
        case 'text':
          updatedProps.additionalStyles = {
            container: styles.text_additional_style,
          };
          updatedProps.maxlength = data.code === 'position' ? 50 : null;
          updatedProps.required =
            (data.submitValidations &&
              data.submitValidations.some((validation) => validation.type === 'required')) ||
            (data.instantValidations &&
              data.instantValidations.some((validation) => validation.type === 'required'));
          updatedProps.hint = t(data.hint, data.hint_params);
          updatedProps.inputType = data.inputType;
          updatedProps.placeholder = data.placeholder;
          updatedProps.defaultValue = data.defaultValue;
          updatedProps.description = data.description;
          updatedProps.descriptionKeys = data.descriptionKeys;
          updatedProps.error = error;
          updatedProps.errorMessage = errorMessage;
          updatedProps.errorMessageParams = errorMessageParams;
          break;
        // no default
      }
    } catch (err) {
      console.log(err);
      return updatedProps;
    }
    return updatedProps;
  };

  useEffect(() => {
    return () => {
      if (callback && data) callback(data.code, null);
    };
  }, []);

  useEffect(() => {
    if (!isFirst) handleSubmitValidations();
    else setIsFirst(false);
  }, [trigger]);

  const handleValidations = (validations, validateValue) => {
    let isValid,
      message,
      messageParams = {};
    if (validations) {
      validations.every((validation) => {
        [isValid, message, messageParams] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError(true);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
          return false;
        } else {
          setError(false);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
        }
        return true;
      });
      return isValid ? true : false;
    }
    return true;
  };

  const handleSubmitValidations = () => {
    let isValid;
    let valuesTobeValidated = isValueList ? valueList.join(';') : value;
    if (isValueList && value) {
      valuesTobeValidated = valuesTobeValidated + ';' + value.trim();
      addToValueList();
    }
    let instantValidationsChecked = handleValidations(data.instantValidations, valuesTobeValidated);
    if (instantValidationsChecked) {
      let submitValidationsChecked = handleValidations(data.submitValidations, valuesTobeValidated);
      if (submitValidationsChecked) isValid = true;
      else isValid = false;
    } else {
      isValid = false;
    }

    if (submitValidation) submitValidation(isValid);
  };

  const handleChange = (value) => {
    setValue(value);
    if (data.instantValidations) {
      handleValidations(data.instantValidations, value);
    } else {
      setError(false);
      setErrorMessage('');
      setErrorMessageParams({});
    }
    if (!isValueList && callback) callback(data.code, value);
  };

  const addToValueList = () => {
    let isValueValid = true;
    if (data.submitValidations) {
      isValueValid = handleValidations(data.submitValidations, value);
    }
    if (isValueValid) {
      setValueList((oldList) => [
        ...oldList,
        ...value.split(';').map((email) => {
          return email.trim();
        }),
      ]);
      setValue(null);
      if (callback)
        callback(
          data.code,
          [
            ...valueList,
            ...value.split(';').map((email) => {
              return email.trim();
            }),
          ].join(';'),
        );
      resetField(!resetkey);
    }
  };

  const getAddMoreButtonMetadata = () => {
    return {
      text: '+ ' + t('general.add-more'),
      click: () => addToValueList(),
      style: styles.add_more_button_style,
    };
  };

  const getChipMetadata = (value) => {
    return {
      text: value,
      style: {
        container: styles.chip_container,
        span: styles.chip_span,
      },
    };
  };

  const removeValueFromList = (value) => {
    setValueList((oldList) => {
      let updatedList = [...oldList];
      updatedList.splice(
        updatedList.findIndex((el) => el === value),
        1,
      );
      if (callback) callback(data.code, updatedList.join(';'));
      return updatedList;
    });
  };

  return (
    <>
      <FlexView className={styles.form_element_container}>
        <FlexView column className={styles.form_element_label_container}>
          <span className={styles.form_element_label}>{t(label)}</span>
          {data && labelHint && (
            <i className={styles.form_element_label_hint}>
              {parseHTML(t(labelHint, labelHintParams))}
            </i>
          )}
        </FlexView>
        <FlexView className={styles.form_element_type_container} column>
          <FlexView>
            <DomRender
              key={resetkey}
              elemType={type}
              {...getElementProps()}
              callback={handleChange}
            />
            {isValueList && <Button metadata={getAddMoreButtonMetadata()} disabled={!value} />}
          </FlexView>
          {isValueList && (
            <FlexView className={styles.chiplist_container} column>
              {valueList &&
                valueList.map((singleValue) => (
                  <Chip
                    metadata={getChipMetadata(singleValue)}
                    showCloseIcon={true}
                    click={() => removeValueFromList(singleValue)}
                  >
                    {singleValue}
                  </Chip>
                ))}
            </FlexView>
          )}
        </FlexView>
      </FlexView>
      {data &&
        data.options &&
        data.options.map((option) => {
          return (
            option.code === attributeConfig(data.code) &&
            option.attributes &&
            option.attributes.map((childAttribute, childIndex) => {
              return (
                //Nested elements
                <FormElement
                  key={childIndex}
                  type={childAttribute.type}
                  label={childAttribute.label}
                  labelHint={childAttribute.label_hint}
                  data={childAttribute}
                  trigger={trigger}
                  submitValidation={submitValidation}
                  attributeConfig={attributeConfig}
                  callback={callback}
                />
              );
            })
          );
        })}
    </>
  );
};

export default FormElement;
