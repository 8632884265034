import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import { UNDEFINED } from '../consts/CommonConstants';
import playIcon from '../images/play_icon.svg';
import styles from './UserManuals.module.css';

function UserManuals() {
  const { t } = useTranslation();
  const headerDesciption = t('user.documents.description');
  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
    header_container: styles.header_container_custom_style,
  };
  const customFooterStyles = {
    container: styles.footer_custom_style,
  };

  const reportsAndManuals = [
    {
      products: [
        {
          title: t('user.documents.analysis-dashboard-onboarding.title'),
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.analysis-dashboard-onboarding.description') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                link: 'https://youtu.be/OLHYuSphd1M',
                length: '02:23 (' + t('general.video') + ')',
              },
              {
                label:
                  t('user.documents.analysis-dashboard-onboarding.description') +
                  ' (' +
                  t('general.languages.german') +
                  ')',
                link: 'https://youtu.be/Cy5gJk1-Oy8',
                length: '02:48 (' + t('general.video') + ')',
              },
            ],
          ],
        },
      ],
    },
    {
      products: [
        {
          title: t('user.documents.users-manual.title'),
          description: t('user.documents.users-manual.description'),
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.users-manual-link') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'zortifySelectAndGrowUserManual.pdf',
              },
            ],
          ],
        },
      ],
    },
    {
      title: t('user.documents.sample-reports.title'),
      description: t('user.documents.sample-reports.description'),
      products: [
        {
          title: 'Zortify Select',
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifySelect_EN.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.german') +
                  ')',
                resource: 'ZortifySelect_DE.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.french') +
                  ')',
                resource: 'ZortifySelect_FR.pdf',
              },
            ],
          ],
        },
        {
          title: 'Zortify Grow',
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifyGrow_EN.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.german') +
                  ')',
                resource: 'ZortifyGrow_DE.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.french') +
                  ')',
                resource: 'ZortifyGrow_FR.pdf',
              },
            ],
            [
              {
                label:
                  t('user.documents.download-sample-reports-without-cbt') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifyGrow_no_CBT_EN.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports-without-cbt') +
                  ' (' +
                  t('general.languages.german') +
                  ')',
                resource: 'ZortifyGrow_no_CBT_DE.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports-without-cbt') +
                  ' (' +
                  t('general.languages.french') +
                  ')',
                resource: 'ZortifyGrow_no_CBT_FR.pdf',
              },
            ],
          ],
        },
        {
          title: 'Zortify Team',
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.download-team-sample-report') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifyTeamShort_EN.pdf',
              },
              {
                label:
                  t('user.documents.download-team-assessment-sample-report') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifyTeamLong_EN.pdf',
              },
            ],
          ],
        },
        {
          title: 'Zortify Leader',
          downloadLinkColumns: [
            [
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.english') +
                  ')',
                resource: 'ZortifyLeader_EN.pdf',
              },
              {
                label:
                  t('user.documents.download-sample-reports') +
                  ' (' +
                  t('general.languages.german') +
                  ')',
                resource: 'ZortifyLeader_DE.pdf',
              },
            ],
          ],
        },
      ],
    },
  ];

  return (
    <PageLayout>
      <FlexView column className={styles.manuals_page_container}>
        <Header
          title={t('user.documents.title')}
          description={headerDesciption}
          customStyles={customHeaderStyles}
        />
        <FlexView className={styles.manuals_container} column>
          {reportsAndManuals.map((item, index) => {
            return (
              <FlexView key={index} className={styles.min_height_auto} column>
                {item.title && <p className={styles.reports_title}>{item.title}</p>}
                {item.description && (
                  <p className={styles.reports_description}>{item.description}</p>
                )}
                {item.products &&
                  item.products.map((product, index) => {
                    return (
                      <FlexView key={index} className={styles.manuals_content_container}>
                        <FlexView className={styles.left_bar} column>
                          <p className={styles.info_title}>{product.title}</p>
                          <p className={styles.reports_description}>{product.description}</p>
                        </FlexView>
                        <FlexView className={styles.right_bar}>
                          {product.downloadLinkColumns.map((downloadLinkColumn, index) => {
                            return (
                              <FlexView key={index} column>
                                {downloadLinkColumn.map((downloadLink) => (
                                  <>
                                    <a
                                      href={
                                        downloadLink.resource
                                          ? `/manuals/${downloadLink.resource}`
                                          : downloadLink.link
                                      }
                                      className={`${styles.sample_report_download_link} ${
                                        downloadLink.length ? styles.margin_bottom_zero : null
                                      }`}
                                      target="_blank"
                                      rel="noreferrer"
                                      download={downloadLink.resource !== UNDEFINED}
                                    >
                                      {downloadLink.label}
                                    </a>
                                    {downloadLink.length && (
                                      <FlexView className={styles.video_container}>
                                        <img
                                          src={playIcon}
                                          className={styles.video_length_play_icon}
                                        />
                                        <span className={styles.video_length_span}>
                                          {downloadLink.length}
                                        </span>
                                      </FlexView>
                                    )}
                                  </>
                                ))}
                              </FlexView>
                            );
                          })}
                        </FlexView>
                      </FlexView>
                    );
                  })}
              </FlexView>
            );
          })}
        </FlexView>
        <Footer customStyles={customFooterStyles} />
      </FlexView>
    </PageLayout>
  );
}

export default UserManuals;
