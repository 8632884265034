import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CreateUser from '../../../Admin/CreateUser/CreateUser';
import EmptyPage from '../../../components/EmptyPage';
import Banner from '../../../components/UI/Banner';
import Button from '../../../components/UI/Button';
import Chip from '../../../components/UI/Chip';
import IconButton from '../../../components/UI/IconButton';
import Modal from '../../../components/UI/Modal';
import Table from '../../../components/UI/PaginatedTable/Table';
import Snackbar from '../../../components/UI/Snackbar';
import Switch from '../../../components/UI/Switch';
import { AD_ADMIN } from '../../../consts/Roles';
import importantNote from '../../../images/important_note.svg';
import parseHTML from '../../../services/HTMLParser';
import httpMessenger from '../../../services/HTTPMessenger';
import userProfileService from '../../../services/UserProfileService';
import Spinner from '../../../Shared/Spinner';
import CompanyRoleMapper from '../CompanyRoleMapper';
import { STATUS } from '../UserStatus';
import styles from './CompanyUsers.module.css';

export const ColumnHeaders = [
  { title: 'PRODUCT NAME' },
  { title: 'LANGUAGE' },
  { title: 'SURVEY ID' },
];

const itemsPerPageMetadata = {
  defaultValue: {
    code: 5,
    label: '5',
  },
  options: [
    {
      code: 5,
      label: '5',
    },
    {
      code: 10,
      label: '10',
    },
    {
      code: 15,
      label: '15',
    },
  ],
  additionalStyles: {
    container: styles.items_per_page_dropdown_additional_style,
    label: styles.items_per_page_dropdown_label_additional_style,
  },
};

const CompanyUsers = ({ Id, cName }) => {
  const { t } = useTranslation();
  const IS_ADMIN = userProfileService.hasRoles([AD_ADMIN]);
  const [userList, setUserList] = useState([]);
  const [dataLength, setDataLength] = useState(null);
  const [pageSize, setPageSize] = useState(5); //No.of rows per page
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [addNewUserModal, setShowAddNewUserModal] = useState(false);
  const [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    message: '',
  });
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [errorMessage, setErrorMessage] = useState(
    'You still have no users for the company. Start off by creating a new user',
  );
  const navigate = useNavigate();

  const tableCustomStyles = {
    tableCell: styles.tableCell,
    tableHeader: styles.tableHeader,
  };

  const showAddNewUserModal = async (value) => {
    setShowAddNewUserModal(value);
  };

  useEffect(() => {
    loadUsers(1, true);
  }, [pageSize, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  //Update any column field of a particular user row.
  const updateField = (id, field, fieldValue, message) => {
    setUserList((previousUserList) => {
      let indexToUpdate = previousUserList.findIndex((user) => user.id === id);
      if (indexToUpdate > -1) {
        let userListCopy = [...previousUserList];
        userListCopy[indexToUpdate] = Object.assign({}, userListCopy[indexToUpdate], {
          [field]: fieldValue,
        });
        return userListCopy;
      } else return previousUserList;
    });
    if (message) {
      setSnackBar({
        showSnackbar: true,
        message,
      });
    }
  };

  const refreshPage = () => {
    loadUsers(1, true);
  };

  const handleSnackbarClose = () => {
    setSnackBar({
      showSnackbar: false,
      message: '',
    });
  };

  const getRolesListForZortify = (memberships) => {
    let companyRoleMapper = new CompanyRoleMapper();
    let roles = companyRoleMapper.getZortifyRoleMap(memberships);
    return roles.join('/') || '-';
  };

  const loadUsers = async (pageNumber, shouldResetData) => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'companyUser', null, null, null, [Id]);
    setIsLoading(false);
    if (httpResult.success) {
      const dataLen = httpResult.result.length;
      setDataLength(dataLen);
      let users = [];
      httpResult.result.forEach((content) => {
        users.push({
          id: content.id,
          firstName: content.firstName,
          lastName: content.lastName,
          email: content.email,
          roles: content.memberships ? getRolesListForZortify(content.memberships) : '-',
          enabled: getStatusElement(
            content.id,
            content.email,
            content.enabled,
            updateField,
            showResetPasswordDialog,
          ),
          password: getResetPasswordElement(
            content.id,
            content.email,
            content.enabled,
            showResetPasswordDialog,
          ),
          actions: getActions(content.id),
        });
      });
      let userListCopy = [...userList];
      if (userListCopy.length === 0 || shouldResetData) {
        userListCopy = new Array(dataLen);
        userListCopy.fill({}, 0);
      }
      const startIndex = (pageNumber - 1) * pageSize;
      userListCopy.splice(startIndex, users.length, ...users);
      setUserList(userListCopy);
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setErrorMessage(errorMessage.join(' '));
    }
  };

  const getActions = (userId) => {
    const actions = [
      {
        elemType: 'button',
        metadata: {
          text: 'User Details',
          click: () => goToUserDetails(userId),
          style: styles.user_details_button_style,
        },
      },
    ];
    return actions;
  };

  const getColumns = () => {
    let columns = {
      firstName: {
        title: 'FIRST NAME',
      },
      lastName: {
        title: 'FAMILY NAME',
      },
      email: {
        title: 'EMAIL',
      },
      roles: {
        title: 'ZORTIFY ROLE(S)',
      },
      enabled: {
        title: 'STATUS',
        customElement: true,
      },
    };
    if (IS_ADMIN) {
      columns.password = {
        title: 'PASSWORD',
        customElement: true,
        additionalStyle: styles.center_align,
      };
    }
    columns.actions = {
      title: '',
    };
    return columns;
  };

  const setPage = async (pageNumber) => {
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(userList[startIndexOfCurrentPage]).length === 0) {
      loadUsers(pageNumber);
    }
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const metadata = itemsPerPageMetadata;
    const callback = resetItemsPerPage;
    return { metadata, callback };
  };

  /** RESET PASSWORD */
  const closeDialog = () => {
    setModalInfo({ show: false });
  };

  //Function to render custom chip element for status in table.
  const getStatusElement = (
    userId,
    userEmail,
    status,
    actionsCallback,
    showResetPasswordDialog,
  ) => {
    let userStatus = status ? STATUS.ENABLED.name : STATUS.DISABLED.name;
    const element = IS_ADMIN
      ? {
          elemType: Switch,
          props: {
            checked: status,
            callback: (status) =>
              toggleUserAccess(userId, userEmail, status, actionsCallback, showResetPasswordDialog),
            tooltipText: userStatus,
          },
        }
      : {
          elemType: Chip,
          props: {
            metadata: {
              text: userStatus,
              style: Object.assign({}, STATUS[userStatus].style),
            },
          },
        };
    return element;
  };

  const getResetPasswordElement = (userId, userEmail, userEnabled, showResetPasswordDialog) => {
    const element = {
      elemType: IconButton,
      props: {
        metadata: {
          src: 'reset.svg',
          tooltipText: userEnabled ? 'Reset Password' : '',
          alt: 'resetpassword',
          style: !userEnabled ? styles.icon_button_disabled : null,
          click: () => (userEnabled ? showResetPasswordDialog(userId, userEmail) : {}),
        },
      },
    };
    return element;
  };

  const goToUserDetails = (userId) => {
    navigate(`/userdetails?userId=${userId}`);
  };

  const toggleUserAccess = async (
    userId,
    userEmail,
    status,
    actionsCallback,
    showResetPasswordDialog,
  ) => {
    const httpResult = await httpMessenger.apiCall(
      'PUT',
      'adminUser',
      null,
      null,
      `${userId}/enabled`,
    );
    if (httpResult.success) {
      let message = 'User has been successfully ' + (status ? 'enabled.' : 'disabled.');
      actionsCallback(
        userId,
        'enabled',
        getStatusElement(userId, userEmail, status, actionsCallback, showResetPasswordDialog),
        message,
      );
      actionsCallback(
        userId,
        'password',
        getResetPasswordElement(userId, userEmail, status, showResetPasswordDialog),
        null,
      );
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      actionsCallback(null, null, null, errorMessage.join(' '));
    }
  };

  const showResetPasswordDialog = (userId, userEmail) => {
    setModalInfo({
      show: true,
      formActions: {
        leftButton: {
          text: 'Cancel',
          action: closeDialog,
          style: styles.modal_cancel_button_style,
        },
        rightButton: {
          text: 'Proceed',
          action: () => resetPassword(userId),
          style: styles.modal_submit_button_style,
        },
      },
      icon: importantNote,
      text: parseHTML(
        `<b>Are you sure you want to change the password for <p style='text-decoration : underline;margin:0'>${userEmail}?</p></b><b>If you reset the password, the user will not be able to login anymore until they have the new password.</b><br/><br/>Note that the password is temporary and the user will be forced to change their password on the first login.`,
      ),
    });
  };

  const resetPassword = async (userId) => {
    const httpResult = await httpMessenger.apiCall('PUT', 'passwordReset', null, null, null, [
      userId,
    ]);
    if (httpResult.success) {
      setModalInfo({
        show: true,
        showPassword: true,
        warningMessage: 'This is the only time you will see the new password!',
        formActions: {
          leftButton: {
            text: 'Close',
            action: closeDialog,
            style: styles.modal_cancel_button_style,
          },
          rightButton: {
            text: 'Copy',
            leftIcon: 'copy_internal_transparent.svg',
            action: () => copyToClipboard(httpResult.result),
            style: styles.modal_submit_button_style,
          },
        },
        text: httpResult.result,
      });
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        icon: importantNote,
        text: errorMessage.join(' '),
        formActions: {
          rightButton: {
            text: 'Close',
            action: closeDialog,
          },
        },
      });
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setSnackBar({
      showSnackbar: true,
      message: 'Password copied to clipboard',
    });
  };

  /** ADD NEW USER */
  const getHeaderActionButtonConfig = () => {
    return {
      text: '+ Add a new user',
      click: () => showAddNewUserModal(true),
      style: styles.custom_header_button_style,
    };
  };

  const bannerMetadata = {
    style: {
      message_span: styles.banner_message_span,
      banner_container: styles.banner_container,
    },
  };

  return (
    <FlexView className={styles.container} column>
      {IS_ADMIN && (
        <FlexView>
          <Button metadata={getHeaderActionButtonConfig()} />
        </FlexView>
      )}
      <FlexView className={styles.table_container} column>
        <Snackbar
          open={snackBar.showSnackbar}
          onClose={handleSnackbarClose}
          message={snackBar.message}
        />
        {dataLength > 0 && (
          <Table
            data={userList}
            dataLength={dataLength}
            alwaysPaginated={true}
            customstyles={tableCustomStyles}
            columns={getColumns()}
            rowsPerPage={pageSize}
            itemsPerPageData={getItemsPerPageData()}
            callback={setPage}
          />
        )}
        <CreateUser
          modalOpen={addNewUserModal}
          modalClose={() => showAddNewUserModal(false)}
          cName={cName}
          callback={refreshPage}
        />
        {modalInfo.show && (
          <Modal formActions={modalInfo.formActions}>
            <FlexView column className={styles.modal_message_content_container}>
              {!modalInfo.showPassword && (
                <>
                  <img
                    src={modalInfo.icon}
                    className={styles.modal_content_icon}
                    alt="modal_icon"
                  />
                  <span className={styles.modal_content_text}>{modalInfo.text}</span>
                </>
              )}
              {modalInfo.showPassword && (
                <>
                  <Banner message={modalInfo.warningMessage} metadata={bannerMetadata} />
                  <FlexView className={styles.label_container}>
                    <FlexView className={styles.password_label_container}>
                      <p>Password</p>
                    </FlexView>
                    <FlexView className={styles.password_container}>
                      <p>{modalInfo.text}</p>
                    </FlexView>
                  </FlexView>
                </>
              )}
            </FlexView>
          </Modal>
        )}
        {isLoading && <Spinner />}
        {!isLoading && dataLength <= 0 && <EmptyPage message={errorMessage} />}
      </FlexView>
    </FlexView>
  );
};
export default CompanyUsers;
