import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';

import styles from './ConfigDisplay.module.css';

export default function ConfigDisplay({ config }) {
  const { t } = useTranslation();
  return (
    <FlexView id="inner" className={styles.container}>
      {config.map((configEntry, index) => {
        return (
          <FlexView key={index} className={styles.config_container} column>
            <span className={styles.config_title}>{t(configEntry.label)}</span>
            <span className={styles.config_value}>
              {t(configEntry.value, { nsSeparator: false })}
            </span>
            {/** To avoid treating ':' in the string as a namespace separator by react i18n,
             * we pass { nsSeparator: false } along with translator.
             * Hence we are also avoiding this setting globally in the config.js*/}
          </FlexView>
        );
      })}
    </FlexView>
  );
}
