import clsx from 'clsx';
import FlexView from 'react-flexview';

import { authService } from '../services/AuthService';
import styles from '../styles/BaseComp.module.css';
import MobileNavigationBar from './MobileDesigns/NavigationBar';
import NavigationBar from './NavigationBar';

/**
 * BaseComp serves as the foundational component
 * which consists of the navigation,
 * central panel (UIView : which renders the main content/pages everytime)
 * as well as the footer.
 */
const PageLayout = ({ children, hideNav, isMobile }) => {
  const isAuthenticated = authService.isAuthenticated();
  return (
    <FlexView className={styles.base_container} column={isMobile}>
      {!hideNav && <NavigationBar />}
      {isMobile && <MobileNavigationBar />}
      <FlexView
        className={clsx(styles.base_frame_container, {
          [styles.base_full_width_container]: hideNav || !isAuthenticated,
        })}
        column
      >
        {children}
      </FlexView>
    </FlexView>
  );
};

export default PageLayout;
