import React, { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Table from '../../components/UI/PaginatedTable/Table';
import DateTimeService from '../../services/DateTimeService';
import httpMessenger from '../../services/HTTPMessenger';
import Spinner from '../../Shared/Spinner';
import EmptyPage from '../EmptyPage';
import Footer from '../Footer';
import Header from '../Header';
import PageLayout from '../PageLayout';
import Chip from '../UI/Chip';
import BookDCSessionDialog from './BookDCSessionDialog';
import styles from './DCProductDetails.module.css';
import { DC_STATUS } from './DCStatus';

export default function DCProductDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [availableUnits, setAvailableUnits] = useState(location.state?.availableUnits);
  const [dataLength, setDataLength] = useState(null);
  const [DCSessionsList, setDCSessionsList] = useState([]);
  const [pageSize, setPageSize] = useState(10); //No.of rows per page
  const [isLoading, setIsLoading] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [unlinkedSessions, setUnlinkedSessions] = useState(null);
  const [showBookDCSessionModal, setShowBookDCSessionModal] = useState(false);

  const dateTime = new DateTimeService();

  const getColumns = () => {
    const columns = {
      session: {
        title: t('general.session'),
      },
      product: {
        title: t('general.product'),
      },
      creationDate: {
        title: t('product.details.creation-date'),
      },
      createdBy: {
        title: t('product.details.creator'),
      },
      token: {
        title: t('product.details.token'),
      },
      coach: {
        title: t('general.coach'),
      },
      validatedOn: {
        title: t('general.validated-on'),
      },
      status: {
        title: t('general.status'),
        customElement: true,
      },
    };
    return columns;
  };

  const setPage = async (pageNumber) => {
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(DCSessionsList[startIndexOfCurrentPage]).length === 0) {
      loadDCSessionsList(pageNumber);
    }
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const callback = resetItemsPerPage;
    return { callback };
  };

  const headerActionButtonConfig = () => {
    let config = {
      elemType: 'button',
      metadata: {
        text: t('debriefing-coaching.request-session'),
        click: showDebriefingRequestModal,
        style: styles.custom_header_button_style,
      },
    };
    return config;
  };

  const showDebriefingRequestModal = () => {
    setShowBookDCSessionModal(true);
  };

  const closeDebriefingRequestModal = (usedUnits) => {
    if (usedUnits && usedUnits > 0) {
      //On success
      setAvailableUnits(availableUnits - usedUnits);
      setUnlinkedSessions(unlinkedSessions + usedUnits);
    }
    setShowBookDCSessionModal(false);
  };

  useEffect(() => {
    loadDCSessionsList(1, true);
  }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStatusChip = (status) => {
    const element = {
      elemType: Chip,
      props: {
        metadata: {
          currentStatus: DC_STATUS[status].key,
          text: DC_STATUS[status].name,
          style: Object.assign({}, DC_STATUS[status].style),
        },
        shouldTranslate: true,
      },
    };
    return element;
  };

  const loadDCSessionsList = async (pageNumber, shouldResetData) => {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('pageSize', pageSize);
    urlSearchParams.append('pageNumber', pageNumber);
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'DCSessionsList', urlSearchParams);
    setIsLoading(false);
    if (httpResult.success) {
      if (httpResult.result) {
        setUnlinkedSessions(httpResult.result.unlinkedSessionsTotal);
      }
      if (httpResult.result && httpResult.result.sessions && httpResult.result.sessions.content) {
        const dataLen = httpResult.result.sessions.page
          ? httpResult.result.sessions.page.totalElements
          : 0;
        setDataLength(dataLen);
        let DCSessionsListCopy = [...DCSessionsList];
        if (DCSessionsListCopy.length === 0 || shouldResetData) {
          DCSessionsListCopy = new Array(dataLen);
          DCSessionsListCopy.fill({}, 0);
        }
        const startIndex = (pageNumber - 1) * pageSize;
        let transformedData = [];
        httpResult.result.sessions.content.forEach((DCSession) => {
          transformedData.push({
            session: DCSession.isDebriefing ? t('general.debriefing') : t('general.coaching'),
            product: DCSession.productName,
            creationDate: dateTime.toLocaleDateString(DCSession.createdOn),
            createdBy: DCSession.createdBy,
            token: DCSession.token || '-',
            coach: DCSession.completedByCoachFirstname || '-',
            validatedOn: DCSession.validatedOn
              ? dateTime.toLocaleDateString(DCSession.validatedOn)
              : '-',
            status: getStatusChip(DCSession.status),
          });
        });
        DCSessionsListCopy.splice(
          startIndex,
          httpResult.result.sessions.content.length,
          ...transformedData,
        );
        setDCSessionsList(DCSessionsListCopy);
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setEmptyMessage(errorMessage.join(' '));
    }
  };

  return (
    <PageLayout>
      <Header
        customStyles={{ header_container: styles.header_container }}
        title={'product-group.name.debriefing-and-coaching'}
        customElement={headerActionButtonConfig()}
        moreDetails={{
          [t('unlinked-sessions')]: unlinkedSessions,
          [t('user.dashboard.available-units')]: availableUnits,
        }}
      ></Header>
      <FlexView className={styles.table_container} column shrink={0}>
        {dataLength > 0 && (
          <FlexView className={styles.table_container_2} column>
            <Table
              data={DCSessionsList}
              dataLength={dataLength}
              callback={setPage}
              columns={getColumns()}
              itemsPerPageData={getItemsPerPageData()}
              rowsPerPage={pageSize}
              alwaysPaginated={true}
            />
          </FlexView>
        )}
        {isLoading && <Spinner />}
        {!isLoading && dataLength <= 0 && (
          <EmptyPage message={emptyMessage || t('error.no-product-to-display')} />
        )}
      </FlexView>
      {showBookDCSessionModal && (
        <BookDCSessionDialog
          availableUnits={availableUnits}
          closeDialog={closeDebriefingRequestModal}
        />
      )}
      <Footer />
    </PageLayout>
  );
}
