import httpMessenger from '../services/HTTPMessenger';

export default class CreateInvitationsPresenter {
  createInvitations = async (body) => {
    const httpResult = await httpMessenger.apiCall('POST', 'invitation', body);
    if (httpResult.success) {
      return {
        success: true,
        result: httpResult.result,
      };
    } else {
      return {
        success: false,
        error: httpResult.error,
      };
    }
  };
}
