import { authService } from './AuthService';

export default function browserIdleWatcher() {
  let timeSinceLastActivity = new Date().getTime();

  const maxInactivity = 60 * 15 * 1000; //15 minute

  const setIntervalId = setInterval(() => {
    const currentTime = new Date().getTime();
    if (currentTime - timeSinceLastActivity > maxInactivity) {
      console.log('User has been inactive for more than ' + maxInactivity / 1000 + ' seconds');
      clearInterval(setIntervalId);
      authService.doLogout();
    }
  }, 1000);

  function activity() {
    timeSinceLastActivity = new Date().getTime();
  }

  //Various DOM events indicating user activity
  var activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

  //Event listeners for each activity.
  activityEvents.forEach(function (eventName) {
    document.addEventListener(eventName, activity, true);
  });
}
