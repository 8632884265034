import clsx from 'clsx';
import { useContext, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { THEMES } from '../consts/CommonConstants';
import { AD_ADMIN, AD_COACH, AD_SALES, AD_USER } from '../consts/Roles';
import ThemeContext from '../contexts/ThemeContext';
import arrowRight from '../images/arrow_right.svg';
import check from '../images/check.svg';
import dashboardIcon from '../images/dashboard_icon.svg';
import failureIcon from '../images/important_note.svg';
import localeIcon from '../images/locale_icon.svg';
import logoutIconCoach from '../images/logout_icon_coach.svg';
import logoutIconExternal from '../images/logout_icon_external.svg';
import logoutIconInternal from '../images/logout_icon_internal.svg';
import profileIcon from '../images/profile_icon_black.svg';
import { authService } from '../services/AuthService';
import httpMessenger from '../services/HTTPMessenger';
import userProfileService from '../services/UserProfileService';
import styles from './SidebarDropDown.module.css';
import Avatar from './UI/Avatar';
import Modal from './UI/Modal';

const MenuItem = ({ to, click, title, titleStyle, containerStyle, children, icon }) => (
  <NavLink to={to} className={styles.remove_default_href_styles}>
    <FlexView className={clsx(styles.individual_menu_container, containerStyle)} onClick={click}>
      <img src={icon} />
      <span className={clsx(styles.nav_menu_display_span, titleStyle)}>{title}</span>
      {children}
    </FlexView>
  </NavLink>
);
function SidebarDropDown(props) {
  const { t, i18n } = useTranslation();
  const { theme, setTheme } = useContext(ThemeContext);
  const showRoleSwitch = userProfileService.hasSomeRole([AD_SALES, AD_COACH, AD_ADMIN]);
  const isUserAndSales = userProfileService.hasRoles([AD_SALES, AD_USER]);
  const IS_COACH = userProfileService.hasRoles([AD_COACH]);
  const currentRole = userProfileService.getCurrentRole();
  const [showViews, setShowViews] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const navigate = useNavigate();

  const logOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    authService.doLogout();
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });
  };

  const showDialog = (errorMessage) => {
    setModalInfo({
      show: true,
      text:
        errorMessage ||
        t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
      formActions: {
        rightButton: {
          text: 'Close',
          action: modalClose,
        },
      },
      icon: failureIcon,
    });
  };

  const toggleShowViews = () => {
    if (!showViews) setShowLanguages(false); //if showviews is false, then next line will turn showviews to true, hence close the languages sidemenu
    setShowViews(!showViews);
  };

  const toggleShowLanguages = () => {
    if (!showLanguages) setShowViews(false); //if showlanguages is false, then next line will turn showlanguages to true, hence close the views sidemenu.
    setShowLanguages(!showLanguages);
  };

  const switchRoles = (event, role) => {
    event.preventDefault();
    event.stopPropagation();
    userProfileService.setCurrentRole(role);
    if (role === AD_SALES) setTheme(THEMES.INTERNAL);
    else if (role === AD_COACH) setTheme(THEMES.COACH);
    else setTheme(THEMES.EXTERNAL);
    navigate('/');
  };

  const switchLanguages = async (event, languageCode) => {
    event.preventDefault();
    event.stopPropagation();

    let httpResult = await httpMessenger.apiCall('PUT', 'updateUserLocale', null, null, null, [
      languageCode,
    ]);
    if (httpResult.success) {
      sessionStorage.setItem('user-locale', languageCode);
      i18n.changeLanguage(languageCode);
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setShowLanguages(false);
      showDialog(errorMessage.join(' '));
    }
  };

  return (
    <>
      <FlexView column className={styles.side_bar_dropdown_container}>
        <FlexView column className={styles.dropdown_profile_container}>
          <Avatar alt={props.fullName} alt_text_style={styles['avatar_custom_style_' + theme]} />
          <span className={styles.profile_name}>{props.fullName}</span>
        </FlexView>
        <FlexView column className={styles.menu_items_container}>
          {showRoleSwitch && (
            <MenuItem title="Dashboards" click={toggleShowViews} icon={dashboardIcon}>
              <img src={arrowRight} className={styles.arrow} alt="arrow_right" />
              {showViews && (
                <FlexView column className={styles.side_bar_child_dropdown_container}>
                  <FlexView column className={styles.menu_items_container}>
                    <>
                      {userProfileService.hasRoles([AD_USER]) && (
                        <MenuItem title="Client" click={(e) => switchRoles(e, AD_USER)}>
                          {currentRole === AD_USER && (
                            <img src={check} className={styles.check} alt="arrow_right" />
                          )}
                        </MenuItem>
                      )}
                      {userProfileService.hasRoles([AD_SALES]) && (
                        <MenuItem title="Sales" click={(e) => switchRoles(e, AD_SALES)}>
                          {currentRole === AD_SALES && (
                            <img src={check} className={styles.check} alt="arrow_right" />
                          )}
                        </MenuItem>
                      )}
                      {userProfileService.hasRoles([AD_COACH]) && (
                        <MenuItem title="Coach" click={(e) => switchRoles(e, AD_COACH)}>
                          {currentRole === AD_COACH && (
                            <img src={check} className={styles.check} alt="arrow_right" />
                          )}
                        </MenuItem>
                      )}
                    </>
                  </FlexView>
                </FlexView>
              )}
            </MenuItem>
          )}
          <MenuItem title={t('user.profile.title')} to="/profile" icon={profileIcon}></MenuItem>
          <MenuItem title={t('general.language')} click={toggleShowLanguages} icon={localeIcon}>
            <img src={arrowRight} className={styles.arrow} alt="arrow_right" />
            {showLanguages && (
              <FlexView column className={styles.side_bar_child_dropdown_container}>
                <FlexView column className={styles.menu_items_container}>
                  <>
                    <MenuItem title="English" click={(e) => switchLanguages(e, 'en')}>
                      {i18n.language === 'en' && (
                        <img src={check} className={styles.check} alt="arrow_right" />
                      )}
                    </MenuItem>
                    <MenuItem title="Deutsch" click={(e) => switchLanguages(e, 'de')}>
                      {i18n.language === 'de' && (
                        <img src={check} className={styles.check} alt="arrow_right" />
                      )}
                    </MenuItem>
                    <MenuItem title="Français" click={(e) => switchLanguages(e, 'fr')}>
                      {i18n.language === 'fr' && (
                        <img src={check} className={styles.check} alt="arrow_right" />
                      )}
                    </MenuItem>
                  </>
                </FlexView>
              </FlexView>
            )}
          </MenuItem>
          <MenuItem
            title={t('general.logout')}
            click={logOut}
            icon={
              isUserAndSales
                ? logoutIconInternal
                : theme === 'coach'
                ? logoutIconCoach
                : logoutIconExternal
            }
            titleStyle={styles['log_out_title_' + theme]}
            containerStyle={styles.logout_menu_item_container}
          />
        </FlexView>
      </FlexView>
      {modalInfo.show && (
        <Modal onClose={modalInfo.close} formActions={modalInfo.formActions}>
          <FlexView column className={styles.modal_content_container}>
            <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
            <span className={styles.modal_content_text}>{modalInfo.text}</span>
          </FlexView>
        </Modal>
      )}
    </>
  );
}

export default SidebarDropDown;
