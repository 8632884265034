import clsx from 'clsx';
import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DomRender from '../components/DomRender';
import EmptyPage from '../components/EmptyPage';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import Button from '../components/UI/Button';
import Chip from '../components/UI/Chip';
import IconButton from '../components/UI/IconButton';
import Modal from '../components/UI/Modal';
import Table from '../components/UI/PaginatedTable/Table';
import TableBulkActions from '../components/UI/PaginatedTable/TableBulkActions';
import Snackbar from '../components/UI/Snackbar';
import { COMPARISON_SIGNS } from '../consts/CommonConstants';
import { useExtendedState } from '../hooks/useExtendedState';
import failureIcon from '../images/important_note.svg';
import successTick from '../images/success_tick_external.svg';
import parseHTML from '../services/HTMLParser';
import httpMessenger from '../services/HTTPMessenger';
import localStorageService from '../Shared/LocalStorageService';
import Spinner from '../Shared/Spinner';
import validationService from '../Shared/ValidationService';
import styles from './CreatedInvitations.module.css';
import CreatedInvitationsPresenter from './CreatedInvitationsPresenter';
import { STATUS } from './Status';

const CreatedInvitations = () => {
  const { t } = useTranslation();
  const invitationsContext = useSelector((state) => state.invitations.context);
  const [toBeCopiedIDs, setToBeCopiedIDs] = useState([]);
  const [availableUnits, setAvailableUnits] = useState(null);
  const [createdInvitations, setCreatedInvitations, getLatestValueOfInvitations] = useExtendedState(
    [],
  );
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [pageSize, setPageSize] = useState(10); //No.of rows per page
  const [dataLength, setDataLength] = useState(null);
  const [showInvitationsWithStatus, setShowInvitationsWithStatus] = useState(
    JSON.parse(localStorageService.getLocalStorageValue('filter-invitation-status')) || {
      [STATUS.CREATED.key]: true,
      [STATUS.COPIED.key]: true,
      [STATUS.REGISTERED.key]: true,
      [STATUS.FINISHED.key]: true,
      [STATUS.COMPLETED.key]: true,
      [STATUS.EXPIRED.key]: true,
    },
  );
  const [tempShowInvitationsWithStatus, setTempShowInvitationsWithStatus] =
    useState(showInvitationsWithStatus);
  const [showStatusFilter, setShowFilterStatus] = useState(false);
  const [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [DCSessions, setDCSessions] = useState(null);
  const [currentInvitationId, setCurrentInvitationId] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageParams, setErrorMessageParams] = useState({});
  const navigate = useNavigate();

  const headerActionButtonConfig = () => {
    let config = {
      elemType: 'button',
      metadata: {
        text: t('user.dashboard.create-invitations'),
        click: () => goToCreateInvitation(),
        style: styles.custom_header_button_style,
      },
    };
    return config;
  };
  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };
  const createdInvitationsPresenter = new CreatedInvitationsPresenter();

  const getDebriefingAndCoachingForm = () => {
    return {
      label: '',
      type: 'text',
      inputType: 'number',
      additionalStyles: {
        container: styles.sesions_input_container,
      },
      placeholder: '0',
      instantValidations: [
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.GT,
            expectation: 0,
            errorMessage: 'error.value.invalid-sessions',
            errorMessageParams: {},
          },
        },
        {
          type: 'valueCompare',
          attributes: {
            compareSign: COMPARISON_SIGNS.LTEQ,
            expectation: Math.floor(
              invitationsContext.availableUnits / invitationsContext.pricePerDC,
            ),
            errorMessage:
              invitationsContext.availableUnits === 0
                ? 'user-dashboard.no-units-and-contact-sales'
                : 'error.value.sessions-gt-x',
            errorMessageParams: {
              max: Math.floor(invitationsContext.availableUnits / invitationsContext.pricePerDC),
            },
          },
        },
      ],
      submitValidations: [
        {
          type: 'required',
          attributes: {},
        },
      ],
    };
  };

  const getDebriefingCoachingProps = () => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let updatedProps = {
      required:
        (debriefingCoachingForm.submitValidations &&
          debriefingCoachingForm.submitValidations.some(
            (validation) => validation.type === 'required',
          )) ||
        (debriefingCoachingForm.instantValidations &&
          debriefingCoachingForm.instantValidations.some(
            (validation) => validation.type === 'required',
          )),
      additionalStyles: debriefingCoachingForm.additionalStyles,
      inputType: debriefingCoachingForm.inputType,
      placeholder: debriefingCoachingForm.placeholder,
      defaultValue: DCSessions,
      error: error,
      errorMessage: errorMessage,
      errorMessageParams: errorMessageParams,
    };
    return updatedProps;
  };

  useEffect(() => {
    if (!showStatusFilter) {
      submitOrCancelFilters(tempShowInvitationsWithStatus);
    }
  }, [showStatusFilter]);

  const loadCreatedInvitations = async (pageNumber, productId, shouldResetData, newFilters) => {
    setIsLoading(true);
    const createdInvitationsVm = await createdInvitationsPresenter.getInvitations(
      pageSize,
      pageNumber,
      productId,
      updateField,
      setMultipleCopyIDs,
      newFilters || showInvitationsWithStatus,
      showDebriefingCoachingModal,
    );
    setIsLoading(false);
    if (createdInvitationsVm.success) {
      const dataLen = createdInvitationsPresenter.getDataLength();
      setDataLength(dataLen);
      let createdInvitationsCopy = [...createdInvitations];
      if (createdInvitationsCopy.length === 0 || shouldResetData) {
        createdInvitationsCopy = new Array(dataLen);
        createdInvitationsCopy.fill({}, 0);
      }
      const startIndex = (pageNumber - 1) * pageSize;
      createdInvitationsCopy.splice(
        startIndex,
        createdInvitationsVm.result.length,
        ...createdInvitationsVm.result,
      );
      setCreatedInvitations(createdInvitationsCopy);
    } else {
      let errorMessage = [];
      createdInvitationsVm.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const setPage = async (pageNumber) => {
    if (!invitationsContext) {
      return;
    }
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(createdInvitations[startIndexOfCurrentPage]).length === 0) {
      loadCreatedInvitations(pageNumber, invitationsContext.productId);
    }
    setToBeCopiedIDs([]);
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const callback = resetItemsPerPage;
    return { callback };
  };

  const toggleShowFilterDropDown = () => {
    setShowFilterStatus((showFilterStatus) => !showFilterStatus);
  };

  const cancelDCSessionsRequest = (event, id) => {
    showDebriefingCoachingModal(event, id);
  };

  const sendDCRequest = (id) => {
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    let isValid;
    isValid = handleValidations(debriefingCoachingForm.instantValidations, DCSessions);
    if (isValid) {
      isValid = handleValidations(debriefingCoachingForm.submitValidations, DCSessions);
    }
    if (isValid) {
      setModalInfo({
        show: true,
        text: parseHTML(
          t('product-details.total-price-of-sessions', {
            total: DCSessions * invitationsContext.pricePerDC,
            amountOfSessions: DCSessions,
          }) +
            '<br/><br/>' +
            t('general.total') +
            ': ' +
            DCSessions * invitationsContext.pricePerDC +
            ' ' +
            t('general.units'),
        ),
        formActions: {
          rightButton: {
            text: t('general.confirm'),
            action: () => confirmDCRequest(id),
            style: styles.modal_submit_button_style,
          },
          leftButton: {
            text: t('general.cancel'),
            action: (e) => cancelDCSessionsRequest(e, id),
            style: styles.modal_cancel_button_style,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      showDCForm: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });
    setError(false);
    setErrorMessage('');
    setDCSessions(null);
    setCurrentInvitationId(null);
  };

  const getDebriefingCoachingModalFormActions = (id) => {
    return {
      rightButton: {
        text: t('general.send-request'),
        action: () => sendDCRequest(id),
        style: styles.modal_submit_button_style,
      },
      leftButton: {
        text: t('general.cancel'),
        action: modalClose,
        style: styles.modal_cancel_button_style,
      },
    };
  };

  const showDebriefingCoachingModal = (event, id) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setCurrentInvitationId(id);
    setModalInfo({
      show: true,
      showDCForm: true,
      headerText: t('product-group.name.debriefing-and-coaching'),
      headerHint: getDCHeaderHint(),
      formActions: { ...getDebriefingCoachingModalFormActions(id) },
    });
  };

  const getDCHeaderHint = () => {
    let finalDescription =
      '<span style ="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/><span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span><br/><br/><span style="font-size: 12px; font-weight: bolder; font-family: poppins">PLACE_HOLDER</span><br/> <span style="font-size: 12px; opacity: 0.7; font-family: poppins">PLACE_HOLDER</span>';
    let descriptionKeys = [
      'general.debriefing',
      'product-details.debriefing-description',
      'general.coaching',
      'product-details.coaching-description',
    ];
    descriptionKeys.forEach((key) => {
      finalDescription = finalDescription.replace('PLACE_HOLDER', t(key));
    });
    return parseHTML(finalDescription);
  };

  const handleChange = (value) => {
    setDCSessions(value);
    let debriefingCoachingForm = getDebriefingAndCoachingForm();
    if (debriefingCoachingForm.instantValidations) {
      handleValidations(debriefingCoachingForm.instantValidations, value);
    } else {
      setError(false);
      setErrorMessage('');
      setErrorMessageParams({});
    }
  };

  const handleValidations = (validations, validateValue) => {
    let isValid,
      message,
      messageParams = {};
    if (validations) {
      for (let validation of validations) {
        [isValid, message, messageParams] = [
          ...validationService.validate(validation.type, validateValue, validation.attributes),
        ];
        if (!isValid) {
          setError(true);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
          break;
        } else {
          setError(false);
          setErrorMessage(message);
          setErrorMessageParams(messageParams);
        }
      }
      return isValid ? true : false;
    } else return true;
  };

  useEffect(() => {
    /* This does not change the value of modalInfo, but updates the values inside
    the function calls handleSubmit and closeDialog to hold the latest
    values of the useState variable (payload) */
    if (modalInfo.show)
      setModalInfo((modalInfo) => ({
        ...modalInfo,
        ...{ formActions: getDebriefingCoachingModalFormActions(currentInvitationId) },
      }));
  }, [DCSessions]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmDCRequest = async (id) => {
    const body = {
      amount: DCSessions,
    };
    let httpResult = await httpMessenger.apiCall('POST', 'sendLinkedDCRequest', body, null, null, [
      id,
    ]);
    if (httpResult.success) {
      if (httpResult.result) {
        let updatedConfigDisplay = createdInvitationsPresenter.configDisplay(
          httpResult.result.config,
          httpResult.result.coachingSessions?.length,
        );
        updateField(httpResult.result.id, 'detailedview', updatedConfigDisplay);
        setAvailableUnits((availableUnits) => {
          return availableUnits - invitationsContext.pricePerDC * DCSessions;
        });
      }
      setModalInfo({
        show: true,
        text: parseHTML(
          t('thanks-for-booking-debriefing-and-coaching-text1') +
            '<br/>' +
            t('thanks-for-booking-debriefing-and-coaching-text2'),
        ),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: successTick,
      });
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
          },
        },
        icon: failureIcon,
      });
    }
  };

  const getFilterButtonMetadata = () => {
    let filterLength = Object.values(showInvitationsWithStatus).filter((v) => v === false).length;
    let filteredKeys = [];
    let additionalText = '';
    if (filterLength > 0) {
      filteredKeys = Object.keys(showInvitationsWithStatus).filter(
        (key) => showInvitationsWithStatus[key] === false,
      );
      if (filterLength > 2)
        additionalText =
          ' (' + t(STATUS[filteredKeys[0]].name) + ', +' + `${filterLength - 1}` + ')';
      else {
        additionalText = [];
        filteredKeys.forEach((key) => additionalText.push(t(STATUS[key].name)));
        additionalText = ' ' + additionalText.join(', ');
      }
    }
    return {
      text: (
        <span>
          {t('general.hidden')}
          <span className={styles.external_color}>{additionalText || ' ' + t('general.none')}</span>
        </span>
      ),
      rightIcon: 'arrow_down.svg',
      click: () => toggleShowFilterDropDown(),
      style: styles.filter_button_style,
    };
  };

  const toggleTempFilters = (status) => {
    setTempShowInvitationsWithStatus((tempShowInvitationsWithStatus) =>
      Object.assign({}, tempShowInvitationsWithStatus, {
        [status]: !tempShowInvitationsWithStatus[status],
      }),
    );
  };

  const getChipMetadata = (status) => {
    return {
      text: t(STATUS[status].name),
      style: Object.assign({}, STATUS[status].style),
    };
  };

  const submitOrCancelFilters = (newFilters) => {
    if (newFilters) {
      setShowInvitationsWithStatus(newFilters);
      localStorageService.setLocalStorageValue(
        'filter-invitation-status',
        JSON.stringify(newFilters),
      );
      if (showInvitationsWithStatus !== newFilters)
        loadCreatedInvitations(1, invitationsContext.productId, true, newFilters);
    }
  };

  //Change when multiple IDs are checked or unchecked for copying.
  const setMultipleCopyIDs = (id, value) => {
    if (value) {
      //Add ID on check
      setToBeCopiedIDs((toBeCopiedIDs) => {
        if (!toBeCopiedIDs.includes(id)) return [...toBeCopiedIDs, id];
        else return toBeCopiedIDs;
      });
    } else {
      //Delete ID on uncheck
      setToBeCopiedIDs((toBeCopiedIDs) => {
        if (toBeCopiedIDs.includes(id))
          return toBeCopiedIDs.filter((toBeCopiedID) => toBeCopiedID !== id);
        else return toBeCopiedIDs;
      });
    }
    setCreatedInvitations((prevCreatedInvitations) => {
      return prevCreatedInvitations.map((createdInvitation) => {
        if (createdInvitation.id === id) {
          createdInvitation.multiplecopy.props.checked = value;
          return createdInvitation;
        }
        // Keep the other items unchanged
        return createdInvitation;
      });
    });
  };

  //Update any column field of a particular invitation.
  const updateField = (id, field, fieldValue, message, success, errorObj) => {
    let createdInvitationsCopy = [];
    if (id) {
      getLatestValueOfInvitations().then((latestCreatedInvitations) => {
        createdInvitationsCopy = [...latestCreatedInvitations];
        let indexToUpdate = createdInvitationsCopy.findIndex((invitation) => invitation.id === id);
        if (indexToUpdate > -1) {
          if (field === 'status') {
            let currentStatus =
              createdInvitationsCopy[indexToUpdate][field].props.metadata.currentStatus;
            let statusToUpdate = fieldValue.props.metadata.currentStatus;
            if (STATUS[currentStatus].value > STATUS[statusToUpdate].value) {
              //Do not update the status if already copied.: i.e., DO NOTHING in this case.
            } else {
              createdInvitationsCopy[indexToUpdate][field] = fieldValue;
              setCreatedInvitations(createdInvitationsCopy);
            }
          } else {
            createdInvitationsCopy[indexToUpdate][field] = fieldValue;
            setCreatedInvitations(createdInvitationsCopy);
          }
        }
      });
    }

    let displayMessage = [];
    if (message) displayMessage.push(t(message));
    if (!success && errorObj) {
      errorObj.forEach((error) => {
        displayMessage.push(t(error.key, error.parameters));
      });
    }
    if (displayMessage.length > 0)
      setSnackBar({
        showSnackbar: true,
        message: displayMessage.join(' '),
      });
  };

  const getColumns = () => {
    const columns = {
      multiplecopy: {
        title: '',
        customElement: true,
        renderCheckbox: true,
      }, // New checkbox column
      email: {
        title: t('product.details.creator'),
      },
      created: {
        title: t('product.details.creation-date'),
      },
      expired: {
        title: t('product.details.expiration-date'),
      },
      token: {
        title: t('product.details.token'),
      },
      comment: {
        title: t('product.details.comment'),
        customElement: true, //indicates whether we want to render a custom element
      },
      status: {
        title: t('general.status'),
        customElement: true,
      },
      actions: {
        title: '',
      },
    };
    return columns;
  };

  const copyMultipleUrisToClipBoard = async () => {
    let linksToCopy = [];
    let tempToBeCopiedIDs = [...toBeCopiedIDs];

    createdInvitations.map((createdInvitation) => {
      if (tempToBeCopiedIDs.includes(createdInvitation.id)) {
        linksToCopy.push(createdInvitation.uri);
      }
      return true;
    });
    let copiedLinks = linksToCopy.join('\n');
    await navigator.clipboard.writeText(copiedLinks);
    const response = await createdInvitationsPresenter.updateMultipleStatuses(STATUS.COPIED.key, [
      ...toBeCopiedIDs,
    ]);
    let message;
    if (response) {
      let statusElement = createdInvitationsPresenter.getStatusChip(STATUS.COPIED.key);
      toBeCopiedIDs.forEach((copiedID) => {
        updateField(copiedID, 'status', statusElement);
      });
    }
    message = t('product-details-links-copied-to-clipboard', { num: toBeCopiedIDs.length });
    setSnackBar({ showSnackbar: true, message });
    toBeCopiedIDs.forEach((copiedID) => {
      setMultipleCopyIDs(copiedID, false);
    });
  };

  const getTableBulkActions = () => {
    let actions = [
      {
        text: t('general.copy'),
        // text: 'Copy',
        style: styles.copy_button_style,
        click: copyMultipleUrisToClipBoard,
      },
    ];
    return actions;
  };

  const getItemsSelectedMessage = () => {
    return toBeCopiedIDs.length > 0
      ? t('product-details-x-invitations-selected', { num: toBeCopiedIDs.length })
      : '';
  };

  const toggleBulkActionSelection = (currentPage, valueToSet, isClicked) => {
    /*If the checkbox has been actually clicked, the value of "clicked" will be "true".
        then we perform the toggle behaviour (i.e., "select all" or "de-select all")
        otherwise we do nothing.*/
    if (isClicked) {
      //valueToSet indicates checked or unchecked. if true, select all.
      setCreatedInvitations((prevCreatedInvitations) => {
        return prevCreatedInvitations.map((createdInvitation, index) => {
          if (
            index >= (currentPage - 1) * pageSize &&
            index < (currentPage - 1) * pageSize + pageSize
          ) {
            createdInvitation.multiplecopy.props.checked = valueToSet;
            return createdInvitation;
          }
          // Keep the other items unchanged
          return createdInvitation;
        });
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackBar({
      showSnackbar: false,
      message: '',
    });
  };

  const goToCreateInvitation = () => {
    navigate(`/createinvitations`);
  };

  useEffect(() => {
    if (!invitationsContext) {
      navigate('/dashboard');
    } else {
      if (
        invitationsContext.productId &&
        localStorageService.getLocalStorageValue(
          'filter-invitation-status-' + invitationsContext.productId,
        )
      ) {
        //TODO: Remove stale key deletion in next release
        localStorageService.deleteLocalStorageValue(
          'filter-invitation-status-' + invitationsContext.productId,
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invitationsContext) {
      loadCreatedInvitations(1, invitationsContext.productId, true);
      setAvailableUnits(invitationsContext.availableUnits);
    }
  }, [pageSize, invitationsContext]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!invitationsContext) {
    return null;
  }

  return (
    <PageLayout>
      <FlexView column className={styles.page_container}>
        <Header
          title={invitationsContext.productName}
          customElement={headerActionButtonConfig()}
          customStyles={customHeaderStyles}
          moreDetails={{ [t('user.dashboard.available-units')]: availableUnits }}
        />
        <FlexView className={styles.table_container} column shrink={0}>
          <Snackbar
            open={snackBar.showSnackbar}
            onClose={handleSnackbarClose}
            message={snackBar.message}
          />
          <FlexView className={styles.action_menu_container}>
            <FlexView className={styles.filter_contents_container} column>
              <span className={styles.filter_title}>{t('product.details.filter-status')}</span>
              <Button metadata={getFilterButtonMetadata()} />
              {showStatusFilter && (
                <>
                  <div
                    id="overlay"
                    className={styles.overlay}
                    onClick={() => toggleShowFilterDropDown()}
                  ></div>
                  <FlexView column className={styles.filter_dropdown_container}>
                    <FlexView column className={styles.menu_items_container}>
                      {Object.keys(STATUS).map((eachStatus, index) => {
                        return (
                          <MenuItem
                            key={index}
                            chipMetadata={getChipMetadata(STATUS[eachStatus].key)}
                            showInvitationsWithStatus={
                              tempShowInvitationsWithStatus[STATUS[eachStatus].key]
                            }
                            click={() => toggleTempFilters(STATUS[eachStatus].key)}
                          />
                        );
                      })}
                    </FlexView>
                  </FlexView>
                </>
              )}
            </FlexView>
            <TableBulkActions
              itemsSelectedMessage={getItemsSelectedMessage()}
              numberOfitemsSelectedForBulkActions={toBeCopiedIDs.length}
              actions={getTableBulkActions()}
            />
          </FlexView>
          {dataLength > 0 && (
            <FlexView className={styles.table_container_2} column>
              <Table
                data={createdInvitations}
                dataLength={dataLength}
                callback={setPage}
                columns={getColumns()}
                itemsPerPageData={getItemsPerPageData()}
                rowsPerPage={pageSize}
                alwaysPaginated={true}
                numberOfitemsSelectedForBulkActions={toBeCopiedIDs.length}
                toggleBulkActionSelection={toggleBulkActionSelection}
                detailedViewExists={true}
              />
            </FlexView>
          )}
          {isLoading && <Spinner />}
          {!isLoading && dataLength <= 0 && <EmptyPage message={t('error.no-invitation-links')} />}
        </FlexView>
        {modalInfo.show && (
          <Modal
            onClose={modalClose}
            formActions={modalInfo.formActions}
            headerText={modalInfo.headerText}
            headerHint={modalInfo.headerHint}
          >
            <FlexView column className={styles.modal_content_container}>
              {modalInfo.icon && (
                <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
              )}
              <span className={styles.modal_content_text}>{modalInfo.text}</span>
              {modalInfo.showDCForm && (
                <>
                  <span className={styles.modal_debriefing_coaching_content}>
                    {t('book-debriefing-and-coaching-text1') +
                      ' ' +
                      t('book-debriefing-and-coaching-text2')}
                  </span>
                  <span className={styles.modal_debriefing_coaching_content}>
                    {t('book-debriefing-and-coaching.select-amount-of-sessions')}
                  </span>
                  <DomRender
                    elemType={'text'}
                    {...getDebriefingCoachingProps()}
                    callback={(value) => handleChange(value)}
                  />
                </>
              )}
            </FlexView>
          </Modal>
        )}
        <Footer />
      </FlexView>
    </PageLayout>
  );
};

const MenuItem = ({ click, showInvitationsWithStatus, chipMetadata }) => {
  const { t } = useTranslation();
  return (
    <FlexView className={clsx(styles.individual_menu_container)}>
      <Chip metadata={chipMetadata} />
      {!showInvitationsWithStatus && (
        <IconButton
          metadata={{
            src: 'eye-slash.svg',
            tooltipText: 'product.details.show-status',
            tooltipPlacement: 'right',
            click: click,
          }}
        />
      )}
      {showInvitationsWithStatus && (
        <IconButton
          metadata={{
            src: 'eye.svg',
            tooltipText: 'product.details.hide-status',
            tooltipPlacement: 'right',
            click: click,
          }}
        />
      )}
    </FlexView>
  );
};

export default CreatedInvitations;
