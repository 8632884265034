import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';

import EmptyPage from '../components/EmptyPage';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import { NULL, UNDEFINED } from '../consts/CommonConstants';
import userProfileService from '../services/UserProfileService';
import Spinner from '../Shared/Spinner';
import styles from './Profile.module.css';

function Profile() {
  const { t } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const getListOfProfileItemsToDisplay = () => {
    const profileItemsList = [
      {
        label: 'general.first-name',
        key: 'given_name',
      },
      {
        label: 'general.last-name',
        key: 'family_name',
      },
      {
        label: 'general.email-address',
        key: 'email',
      },
      {
        label: 'general.company',
        key: 'company',
      },
    ];
    return profileItemsList;
  };

  /** Adapting to mobile screens */
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change state based on window width
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getUserProfile = () => {
    let profileItemsListToDisplay = getListOfProfileItemsToDisplay();
    const userProfilePm = userProfileService.getUserProfile();
    const userProfileVm = [];
    if (userProfilePm !== NULL || userProfilePm !== UNDEFINED) {
      profileItemsListToDisplay.map((profileItem) => {
        let userProfileItemVm = { label: profileItem.label, value: userProfilePm[profileItem.key] };
        userProfileVm.push(userProfileItemVm);
        return true;
      });
      return userProfileVm;
    } else {
      return null;
    }
  };

  const customHeaderStyles = {
    headerLine: styles.header_custom_style,
  };
  const customFooterStyles = {
    container: styles.footer_custom_style,
  };

  useEffect(() => {
    setProfile(getUserProfile());
    setIsLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout hideNav={isMobile} isMobile={isMobile}>
      <FlexView column className={styles.profile_container}>
        <Header title={t('user.profile.title')} customStyles={customHeaderStyles} />
        {!isLoading && profile && (
          <FlexView className={styles.profile_content_container}>
            <FlexView className={styles.left_bar} column>
              <p className={styles.info_title}> {t('user.profile.personal-info-title')} </p>
              <p className={styles.info_description}>
                {' '}
                {t('user.profile.personal-info-description')}{' '}
              </p>
            </FlexView>

            <FlexView className={styles.right_bar} column>
              {profile.map((item, index) => (
                <FlexView key={index} className={styles.label_container} column>
                  <p className={`${styles.textbox} ${styles.profile_label}`}>{t(item.label)}</p>
                  <p className={`${styles.textbox} ${styles.profile_value}`}>{item.value}</p>
                </FlexView>
              ))}
            </FlexView>
          </FlexView>
        )}
        {!isLoading && !profile && <EmptyPage message="No information to display." />}
        {isLoading && <Spinner />}
        <Footer customStyles={customFooterStyles} />
      </FlexView>
    </PageLayout>
  );
}

export default Profile;
