import clsx from 'clsx';
import { useContext, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { AD_COACH, AD_SALES } from '../consts/Roles';
import ThemeContext from '../contexts/ThemeContext';
import zortifyLogo from '../images/zortify_logo_sidemenu.svg';
import userProfileService from '../services/UserProfileService';
import styles from '../styles/NavigationBar.module.css';
import SidebarDropDown from './SidebarDropDown';
import Avatar from './UI/Avatar';

const NavigationItem = ({ to, children }) => {
  const { theme } = useContext(ThemeContext);

  const getClassNames = (isActive) => {
    return isActive
      ? theme === 'coach'
        ? styles.activeNav_coach
        : styles.activeNav
      : theme === 'coach'
      ? styles.inactiveNav_coach
      : styles.inactiveNav;
  };
  return (
    <NavLink to={to} className={({ isActive }) => getClassNames(isActive)}>
      <FlexView className={styles.nav_individual_menu_container}>
        <span className={styles.nav_menu_display_span}>{children}</span>
      </FlexView>
    </NavLink>
  );
};

const NavigationBar = () => {
  const [showSideBarDropdown, setShowSideBarDropdown] = useState(false);
  const { theme } = useContext(ThemeContext);
  let { t } = useTranslation();
  const USER_ROLE = userProfileService.getCurrentRole();
  const navigate = useNavigate();

  const FIRST_NAME = userProfileService.getFirstName();
  const LAST_NAME = userProfileService.getLastName();
  const FULL_NAME = FIRST_NAME + ' ' + LAST_NAME;

  const showOrHideSideBarDropdown = (value) => {
    setShowSideBarDropdown(value);
  };

  const goToHomePage = () => {
    navigate('/');
  };

  const getIndMenuContainerClassNames = () => {
    return theme === 'coach'
      ? clsx(styles.nav_individual_menu_container, styles.inactiveNav_coach)
      : clsx(styles.nav_individual_menu_container, styles.inactiveNav);
  };

  return (
    <>
      <FlexView
        className={`${styles.nav_sidebar_container} ${styles['nav_sidebar_container_' + theme]}`}
        column
      >
        <img
          src={zortifyLogo}
          className={styles.nav_sidebar_logo}
          alt="zortifyLogo"
          onClick={goToHomePage}
        />

        <FlexView column className={styles.nav_menu_container}>
          {USER_ROLE === AD_COACH ? (
            <>
              <NavigationItem to="/coach">{t('user.sessions.title')}</NavigationItem>
            </>
          ) : USER_ROLE === AD_SALES ? (
            <>
              <NavigationItem to="/companylist">Companies</NavigationItem>
              <NavigationItem to="/users">Users</NavigationItem>
            </>
          ) : (
            <>
              <NavigationItem to="/dashboard">{t('user.dashboard.title')}</NavigationItem>
              <NavigationItem to="/contact">{t('user.contact.title')}</NavigationItem>
              <NavigationItem to="/usermanuals">{t('user.documents.title')}</NavigationItem>
            </>
          )}

          <FlexView column className={styles.nav_bottom_menu_container}>
            <>
              <FlexView
                className={getIndMenuContainerClassNames()}
                onClick={() => showOrHideSideBarDropdown(!showSideBarDropdown)}
              >
                <FlexView className={styles.nav_avatar_container}>
                  <Avatar alt={FULL_NAME} />
                  {FULL_NAME}
                </FlexView>
              </FlexView>
              {showSideBarDropdown && (
                <>
                  <div
                    id="overlay"
                    className={styles.overlay}
                    onClick={() => showOrHideSideBarDropdown(false)}
                  ></div>
                  <SidebarDropDown fullName={FULL_NAME} />
                </>
              )}
            </>
          </FlexView>
        </FlexView>
      </FlexView>
    </>
  );
};

export default NavigationBar;
