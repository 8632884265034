export default class CompanyRoleMapper {
  getCompanyRoleMapping = (memberships) => {
    let companies = {};
    memberships.map((company) => {
      if (companies[company.companyId]) {
        companies[company.companyId].roles.push(company.role);
      } else {
        companies[company.companyId] = {};
        companies[company.companyId].companyName = company.companyName;
        companies[company.companyId].roles = [];
        companies[company.companyId].roles.push(company.role);
      }
      return true;
    });
    return companies;
  };

  //Only roles that the user has for Zortify
  getZortifyRoleMap = (memberships) => {
    let roles = [];
    memberships.map((company) => {
      if (company.companyName.includes('Zortify')) {
        roles.push(company.role);
      }
    });
    return roles;
  };
}
