import { UserService } from '@zortify/react-keycloak';

import userProfileService from './UserProfileService';

class AuthService {
  auth = null;
  isAuthenticated = () => {
    let auth = UserService.isAuthenticated();
    if (!this.auth && auth) {
      let user = this.decodeJWT();
      userProfileService.setUserProfile(user);
    } else if (this.auth && !auth) userProfileService.setUserProfile(null);
    this.auth = auth;
    return this.auth;
  };

  getJWTToken = () => {
    return UserService.getToken();
  };

  decodeJWT = () => {
    let token = this.getJWTToken();
    if (!token) return;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  doLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };
}

export let authService = new AuthService();
