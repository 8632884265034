import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import FlexView from 'react-flexview';
import { useNavigate } from 'react-router-dom';

import ThemeContext from '../../contexts/ThemeContext';
import zortifyLogo from '../../images/zortify_logo_sidemenu.svg';
import userProfileService from '../../services/UserProfileService';
import SidebarDropDown from '../SidebarDropDown';
import Avatar from '../UI/Avatar';
import styles from './NavigationBar.module.css';

export default function NavigationBar() {
  const FULL_NAME = userProfileService.getFirstName() + ' ' + userProfileService.getLastName();
  const [showSideBarDropdown, setShowSideBarDropdown] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const toggleSideBarDropDownDisplay = () => {
    console.log('toggle');
    setShowSideBarDropdown(!showSideBarDropdown);
  };

  const goToHomePage = () => {
    navigate('/');
  };

  const header_container_style = clsx(styles.header_container, styles['header_container_' + theme]);
  return (
    <FlexView className={header_container_style}>
      <img
        src={zortifyLogo}
        onClick={goToHomePage}
        className={styles.nav_sidebar_logo}
        alt="zortifyLogo"
      />
      <Avatar
        alt_text_style={styles.avatar_custom_style}
        alt={FULL_NAME}
        onClick={toggleSideBarDropDownDisplay}
      />
      {showSideBarDropdown && (
        <>
          <div id="overlay" className={styles.overlay} onClick={toggleSideBarDropDownDisplay}></div>
          <SidebarDropDown fullName={FULL_NAME} />
        </>
      )}
    </FlexView>
  );
}
