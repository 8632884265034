import styles from './DCStatus.module.css';

export const DC_STATUS = {
  NEW: {
    key: 'NEW',
    name: 'general.new',
    value: 0,
    style: {
      container: styles.new_container,
      span: styles.new_span,
    },
  },
  COMPLETED: {
    key: 'COMPLETED',
    name: 'general.completed',
    value: 1,
    style: {
      container: styles.completed_container,
      span: styles.completed_span,
    },
  },
};
