import React, { useEffect, useState } from 'react';
import FlexView from 'react-flexview';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import PageNotFound from '../components/PageNotFound';
import Button from '../components/UI/Button';
import Input from '../components/UI/Input';
import Modal from '../components/UI/Modal';
import { AD_COACH } from '../consts/Roles';
import warningIcon from '../images/important_note.svg';
import successTick from '../images/success_tick_coach.svg';
import parseHTML from '../services/HTMLParser';
import httpMessenger from '../services/HTTPMessenger';
import userProfileService from '../services/UserProfileService';
import Spinner from '../Shared/Spinner';
import styles from './CoachDashboard.module.css';

export default function CoachDashboard({}) {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const IS_COACH = userProfileService.hasRoles([AD_COACH]);
  const [isLoading, setisLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [validationMessageParams, setValidationMessageParams] = useState({});
  const [invitationDetails, setInvitationDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [showUnlinkedSession, setShowUnlinkedSession] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });

  /** Adapting to mobile screens */
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    getTokenFromURL();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change state based on window width
    };
    if (IS_COACH) userProfileService.setCurrentRole(AD_COACH);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getTokenFromURL = () => {
    let token = searchParams.get('token');
    if (token) getInvitationDetails(token);
  };

  const handleSearchSubmit = async (event, searchText) => {
    if (event) event.preventDefault();
    if (searchText) getInvitationDetails(searchText);
  };

  const setSearch = (value) => {
    setSearchTerm(value);
    if (value === '') {
      setSearchTerm(null);
      handleSearchSubmit(null, null);
      setInvitationDetails({});
      setValidationMessage('');
      setValidationMessageParams({});
    }
  };

  const inputMetadata = {
    additionalStyles: {
      input: styles.search_input_additional_style,
      container: styles.search_input_container,
    },
    placeholder: 'Search by Token',
  };

  const getInvitationDetails = async (token) => {
    setisLoading(true);
    let httpResult = await httpMessenger.apiCall('GET', 'invitationUpdate', null, null, null, [
      token,
    ]);
    setisLoading(false);
    if (httpResult.success) {
      setInvitationDetails(httpResult.result);
      let coachingSessions = [...httpResult.result.coachingSessions];
      if (
        coachingSessions.some((coachingSession) => coachingSession.status === 'NEW') ||
        httpResult.result.unlinkedSessionsCount > 0
      ) {
        setValidationMessage('user.sessions.validate-session');
        setValidationMessageParams({
          candidate:
            httpResult.result.registrarFirstname + ' ' + httpResult.result.registrarLastname,
          product: httpResult.result.productName,
          token: httpResult.result.token,
        });
      }
      if (!coachingSessions.some((coachingSession) => coachingSession.status === 'NEW')) {
        if (httpResult.result.unlinkedSessionsCount > 0) setShowUnlinkedSession(true);
        else {
          setValidationMessage('user-sessions.validated');
          setValidationMessageParams({
            candidate:
              httpResult.result.registrarFirstname + ' ' + httpResult.result.registrarLastname,
            product: httpResult.result.productName,
            token: httpResult.result.token,
          });
          setModalInfo({
            show: true,
            text: t('no-sessions-to-validate'),
            formActions: {
              rightButton: {
                text: t('general.close'),
                action: modalClose,
                style: styles.modal_close_button_style,
              },
            },
            icon: warningIcon,
          });
        }
      }
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
            style: styles.modal_close_button_style,
          },
        },
        icon: warningIcon,
      });
    }
  };

  const validateSession = async () => {
    let coachID = userProfileService.getSub();
    let body = {
      coachId: coachID,
    };
    let httpResult = await httpMessenger.apiCall('PATCH', 'sendLinkedDCRequest', null, body, null, [
      invitationDetails.id,
    ]);
    if (httpResult.success) {
      setInvitationDetails((oldDetails) => {
        let updatedDetails = { ...oldDetails };
        let indexOfSessionToUpdate = updatedDetails.coachingSessions.findIndex(
          (session) => session.id === httpResult.result.id,
        );
        if (indexOfSessionToUpdate > -1) {
          updatedDetails.coachingSessions[indexOfSessionToUpdate].status = 'COMPLETED';
        } else {
          updatedDetails.coachingSessions.push(httpResult.result);
          setShowUnlinkedSession(false);
        }
        return updatedDetails;
      });
      setModalInfo({
        show: true,
        text: t('validate-coaching-session-successful'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
            style: styles.modal_close_button_style,
          },
        },
        icon: successTick,
      });
    } else {
      let errorMessage = [];
      httpResult.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      setModalInfo({
        show: true,
        text:
          errorMessage.join(' ') ||
          t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
        formActions: {
          rightButton: {
            text: t('general.close'),
            action: modalClose,
            style: styles.modal_close_button_style,
          },
        },
        icon: warningIcon,
      });
    }
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {},
      icon: null,
    });
  };

  const showConfirmValidateModal = () => {
    setModalInfo({
      show: true,
      text: parseHTML(
        t('validate-coaching-session') + '<br/>' + t('product-details-are-you-sure-to-proceed'),
      ),
      formActions: {
        rightButton: {
          text: t('general.confirm'),
          action: () => validateSession(),
          style: styles.modal_submit_button_style,
        },
        leftButton: {
          text: t('general.cancel'),
          action: modalClose,
          style: styles.modal_cancel_button_style,
        },
      },
      icon: warningIcon,
    });
  };

  const getValidateButtonProps = (sessionStatus) => {
    let metadata =
      sessionStatus === 'NEW'
        ? {
            text: t('general.validate'),
            click: () => showConfirmValidateModal(),
            style: styles.validate_button_style,
          }
        : {
            text: t('general.validated'),
            disabled: true,
            click: () => {},
            style: styles.validated_button_style,
          };
    return metadata;
  };

  return IS_COACH ? (
    <>
      <PageLayout hideNav={isMobile} isMobile={isMobile}>
        <Header title={t('user.sessions.title')} />
        {isLoading && <Spinner />}
        <FlexView shrink={0} className={styles.input_parent_container}>
          <form onSubmit={(e) => handleSearchSubmit(e, searchTerm)}>
            <Input {...inputMetadata} callback={setSearch} />
          </form>
        </FlexView>
        <span className={styles.validation_message}>
          {parseHTML(t(validationMessage, validationMessageParams))}
        </span>
        <FlexView className={styles.session_validate_container} column>
          {invitationDetails.coachingSessions &&
            invitationDetails.coachingSessions.map((session) => (
              <>
                <FlexView className={styles.session_validate_block}>
                  <span>{t('debriefing-or-coaching-session')}</span>
                  <Button metadata={getValidateButtonProps(session.status)}></Button>
                </FlexView>
              </>
            ))}
          {showUnlinkedSession && (
            <FlexView className={styles.session_validate_block}>
              <span>{t('debriefing-or-coaching-session')}</span>
              <Button metadata={getValidateButtonProps('NEW')}></Button>
            </FlexView>
          )}
        </FlexView>
        <Footer />
      </PageLayout>

      {modalInfo.show && (
        <Modal onClose={modalInfo.close} formActions={modalInfo.formActions}>
          <FlexView column className={styles.modal_content_container}>
            <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
            <span className={styles.modal_content_text}>{modalInfo.text}</span>
          </FlexView>
        </Modal>
      )}
    </>
  ) : (
    <PageNotFound />
  );
}
