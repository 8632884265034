export const UNDEFINED = undefined;
export const NULL = null;
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const THEMES = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
  COACH: 'coach',
};
export const COMPARISON_SIGNS = {
  GT: 'gt',
  LT: 'lt',
  GTEQ: 'gteq',
  LTEQ: 'lteq',
};
export const STATE_PARAMS = 'stateParams';
