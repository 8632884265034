import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCompanyListContext } from '../../actions/CompanyActions';
import CreateCompany from '../../Admin/CreateCompany/CreateCompany';
import EmptyPage from '../../components/EmptyPage';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageLayout from '../../components/PageLayout';
import Input from '../../components/UI/Input';
import Table from '../../components/UI/PaginatedTable/Table';
import { UNDEFINED } from '../../consts/CommonConstants';
import { AD_ADMIN } from '../../consts/Roles';
import httpMessenger from '../../services/HTTPMessenger';
import userProfileService from '../../services/UserProfileService';
import Spinner from '../../Shared/Spinner';
import styles from './CompanyList.module.css';

const columns = {
  id: {
    title: '#ID',
  },
  companyName: {
    title: 'COMPANY NAME',
  },
  invoicingCompanyName: {
    title: 'INVOICE COMPANY',
  },
  owner: {
    title: 'OWNER',
  },
  actions: {
    title: '',
  },
};

const CompanyList = () => {
  const { t } = useTranslation();
  const title = 'Companies Overview';
  const [companyList, setCompanyList] = useState([]);
  const [pageSize, setPageSize] = useState(10); //No.of rows per page
  const [dataLength, setDataLength] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addNewCompanyModal, setShowAddNewCompanyModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(UNDEFINED);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableCustomStyles = {
    tableCell: styles.tableCell,
  };
  const IS_ADMIN = userProfileService.hasRoles([AD_ADMIN]);

  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };

  const goToCompanyDetails = (companyId) => {
    navigate(`/companydetails?companyId=${companyId}`);
  };

  const getActions = (companyId) => {
    const actions = [
      {
        elemType: 'button',
        metadata: {
          text: 'Company Details',
          click: () => goToCompanyDetails(companyId),
          style: styles.company_details_button_style,
        },
      },
    ];
    return actions;
  };

  const loadCompanies = async (pageNumber, shouldResetData, searchText, active) => {
    setIsLoading(true);
    const httpResult = await httpMessenger.apiCall('GET', 'company', {
      pageNumber,
      pageSize,
      searchTerm: searchText,
      active,
    });
    setIsLoading(false);
    if (httpResult.success) {
      const dataLen = httpResult.result.page.totalElements;
      if (httpResult.result.content.length > 0) setDataLength(dataLen);
      let companyListCopy = [...companyList];
      if (companyListCopy.length === 0 || shouldResetData) {
        companyListCopy = new Array(dataLen);
        companyListCopy.fill({}, 0);
        setErrorMessage('No companies found.');
      }
      const startIndex = (pageNumber - 1) * pageSize;
      let companyContent = [];
      if (httpResult.result.content.length === 0)
        setErrorMessage('Something went wrong. Please try again later!');
      else {
        httpResult.result.content.forEach((company) => {
          companyContent.push({
            id: company.id,
            invoicingCompanyHsId: company.invoicingCompanyHsId,
            companyName: company.name,
            invoicingCompanyName: company.invoicingCompanyName,
            owner: company.owner,
            actions: getActions(company.id),
          });
        });
        companyListCopy.splice(startIndex, companyContent.length, ...companyContent);
        setCompanyList(companyListCopy);
        dispatch(setCompanyListContext([...companyListCopy]));
      }
    } else {
      let errorMsg = [];
      httpResult.error.forEach((error) => {
        errorMsg.push(t(error.key, error.parameters));
      });
      setErrorMessage(errorMsg.join(' '));
    }
  };

  const inputMetadata = {
    additionalStyles: {
      input: styles.search_input_additional_style,
      container: styles.search_input_container,
    },
    placeholder: 'Search by Company',
  };

  const setPage = async (pageNumber) => {
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (
      companyList &&
      companyList[startIndexOfCurrentPage] !== UNDEFINED &&
      Object.keys(companyList[startIndexOfCurrentPage]).length === 0
    ) {
      loadCompanies(pageNumber, false, searchTerm);
    }
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const callback = resetItemsPerPage;
    return { callback };
  };

  const setSearch = (value) => {
    setSearchTerm(value);
    if (value === '') {
      setSearchTerm(null);
      handleSearchSubmit(null, null);
    }
  };

  const handleSearchSubmit = async (event, searchText) => {
    if (event) event.preventDefault();
    loadCompanies(1, true, searchText);
  };

  const showAddNewCompanyModal = async (value) => {
    setShowAddNewCompanyModal(value);
  };

  const headerActionButtonConfig = () => {
    return {
      elemType: 'button',
      metadata: {
        text: '+ Add a new company',
        click: () => showAddNewCompanyModal(true),
        style: styles.custom_header_button_style,
      },
    };
  };

  useEffect(() => {
    loadCompanies(1, true);
  }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout>
      <FlexView column>
        <Header
          title={title}
          customStyles={IS_ADMIN ? customHeaderStyles : null}
          customElement={IS_ADMIN ? headerActionButtonConfig() : null}
        />
        <FlexView className={styles.table_container} column>
          <FlexView shrink={0}>
            <form onSubmit={(e) => handleSearchSubmit(e, searchTerm)}>
              <Input {...inputMetadata} callback={setSearch} />
            </form>
          </FlexView>
          <FlexView className={styles.table_container_2} column>
            {dataLength > 0 && (
              <Table
                data={companyList}
                dataLength={dataLength}
                customstyles={tableCustomStyles}
                columns={columns}
                rowsPerPage={pageSize}
                alwaysPaginated={true}
                itemsPerPageData={getItemsPerPageData()}
                callback={setPage}
              />
            )}
          </FlexView>
          {isLoading && <Spinner />}
          {!isLoading && dataLength <= 0 && <EmptyPage message={errorMessage} />}
        </FlexView>
        <CreateCompany
          modalOpen={addNewCompanyModal}
          modalClose={() => showAddNewCompanyModal(false)}
        />
        <Footer />
      </FlexView>
    </PageLayout>
  );
};
export default CompanyList;
